import React from "react";
import FilterCard from "./FilterCard";
import BrandSillder from "./brandSlider";

const AllProduct = () => {
  return (
    <>
      <FilterCard />
      <br />
    </>
  );
};
export default AllProduct;
