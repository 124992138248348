import "./App.css";
import Ad from "./components/Ads/Ads";
import Pages from "./components/pages/Pages";
function App() {
  return (
    <>
      <Pages />
    </>
  );
}
export default App;
